@import-normalize;

body {
  margin: 0;
  font-family: 'VolvoNovum', 'VolvoAntikva', sans-serif;
  font-size: 1vw;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.015em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}